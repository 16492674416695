import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Banner from "../../components/CloudSolutions/Banner"
import Cases from "../../components/CommonComponents/Cases"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { ParallaxProvider } from "react-scroll-parallax"
import { mainUrl } from "../../js/config"
import dataForProjects from "../../information/CloudSolutions/dataForProjects.json"
import Technologies from "../../components/CloudSolutions/Technologies"
import Necessary from "../../components/CloudSolutions/Necessary"
import Computing from "../../components/CloudSolutions/Computing"
import Keys from "../../components/CloudSolutions/Keys"
import Experience from "../../components/SingleService/Common/Experience"
import Choose from "../../components/CloudSolutions/Choose"
import Services from "../../components/CloudSolutions/Services"
import Industries from "../../components/CloudSolutions/Industries"
import dataForCloud from "../../information/CloudSolutions/dataForServices"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1021,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function CloudSolutions() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  const { ogImage } = dataForCloud;
  return (
    <div>
      <SEO
        title="Cloud Development Services | OS-System"
        description="Improve your enterprise with our experienced cloud development services. We come up with high-performance and very secure scalable cloud solutions to meet your specific demands. Experience entirely seamless migration, integration, and optimization with OS System. We can be reached anytime"
        canonical={`${mainUrl}/expertise/cloud-solutions/`}
        ogImage={ogImage}
      />
      <ParallaxProvider className="parallax-provider">
        <Layout showFormPoint={300}>
          <Banner />
          <Necessary />
          <Experience />
          <Services />
          <Computing />
          <Industries />
          <Choose />
          <Keys />
          <Technologies />
          <Cases
            projects={dataForProjects}
            settings={settings}
            headerText="Work Projects"
            customClass="cloud"
          />
          <ContactUsExpertise form={0} url={sendUrl} />
        </Layout>
      </ParallaxProvider>
    </div>
  )
}
