import React from "react"
import { Link } from "react-scroll" 
import { CloudRobot } from "../../../images/CloudSolutions/Graphics"
import dataForCloud from "../../../information/CloudSolutions/dataForServices"

import "./Banner.scss"

export default function Banner() {
  const { banner } = dataForCloud;
  return (
     <section className="banner-cloud-parent">
      <div className="banner-cloud-child">
        <div className="banner-text-container">
          <h1 className="banner-title">{banner.title}</h1>
          <h2 className="banner-subtitle">{banner.subtitle}</h2>
          <div className="banner-description">{banner.description}</div>
          <div className="banner-btns-container">
            <Link
              to="contact-us-form"
              spy={true}
              smooth={"easeOutSine"}
              offset={-40}
              duration={1000}
            >
              <button className="banner-contact-btn">
                Contact us
              </button>
            </Link>
          </div>
        </div>
        <div className="banner-img-container">
          <CloudRobot style="banner-img"/>
        </div>
      </div>
    </section>
  )
}
