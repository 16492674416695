import React from "react"
import "./Industries.scss"

import dataForCloud from "../../../information/CloudSolutions/dataForServices"

const CaseComponent = ({ data }) => {
  const { title, description, image } = data;
  return (
    <div className="industry-case">
      <img src={image} className="industry-case-image" />
      <div className="industry-case-title">{title}</div>
      <div className="industry-case-description">{description}</div>
    </div>
  )
}


export default function Industries() {
  const { title, description, cases } = dataForCloud.industries;
  return (
    <section className="industries-wrapper">
      <h2 className="industries-title">{title}</h2>
      <div className="industries-description">{description}</div>
      <div className="industries-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </section>
  )
}