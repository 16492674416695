import React from "react";
import dataForCloud from "../../../information/CloudSolutions/dataForServices"
import './Technologies.scss';

const Technologies = () => {
  const { title, cases } = dataForCloud.tech;
  return (
    <div className="tech-cloud-tech-wrapper">
      <div className="tech-content">
        <h2 className="title">
          {title}
        </h2>
        <div className="images-wrapper">
          {cases.map((item) => (<div className="image-container">
            <img src={item}/>
          </div>))}
        </div>
      </div>
    </div>
  );
};

export default Technologies;