import React from "react";
import './Computing.scss';

import dataForCloud from "../../../information/CloudSolutions/dataForServices"

const CaseComponent = ({ data }) => {
  const { title, description, image } = data;
  return (
    <div className="computing-case">
      <div className="case-img-container">
        <img src={image} alt="web development icon" />
      </div>
      <div className="case-text">
        <h3 className="case-title">{title}</h3>
        <div className="case-description">{description}</div>
      </div>
    </div>
  );
};

export default function Computing() {
  const { title, description, cases } = dataForCloud.computing;
  return (
    <section className="computing-wrapper">
      <div className="computing-text">
        <h2 className="computing-title">{title}</h2>
        <div className="computing-description">{description}</div>
      </div>
      <div className="computing-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </section>
  );
};
