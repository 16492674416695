import OgImage from '../../images/CloudSolutions/og_image.png';

import CloudMigration from '../../images/CloudSolutions/Explore/cloudmigration.svg';
import Infrastructure from '../../images/CloudSolutions/Explore/infrastructure.svg';
import Native from '../../images/CloudSolutions/Explore/native.svg';
import Devops from '../../images/CloudSolutions/Explore/devops.svg';
import CloudSecurity from '../../images/CloudSolutions/Explore/security.svg';
import MultiCloud from '../../images/CloudSolutions/Explore/multicloud.svg';

import Scalability from '../../images/CloudSolutions/Computing/scalability.svg';
import CostSavings from '../../images/CloudSolutions/Computing/cost.svg';
import Security from '../../images/CloudSolutions/Computing/security.svg';
import Collaboration from '../../images/CloudSolutions/Computing/collaboration.svg';
import Business from '../../images/CloudSolutions/Computing/business.svg';
import Deployment from '../../images/CloudSolutions/Computing/deployment.svg';

import Finance from '../../images/CloudSolutions/Industries/1.svg';
import Healthcare from '../../images/CloudSolutions/Industries/2.svg';
import Ecommerce from '../../images/CloudSolutions/Industries/3.svg';
import Manufacturing from '../../images/CloudSolutions/Industries/4.svg';
import TechSaaS from '../../images/CloudSolutions/Industries/5.svg';
import Education from '../../images/CloudSolutions/Industries/6.svg';

import ProvenCloud from '../../images/CloudSolutions/Choose/1.svg';
import CostOptimization from '../../images/CloudSolutions/Choose/2.svg';
import SecurityFirst from '../../images/CloudSolutions/Choose/3.svg';
import FullCycle from '../../images/CloudSolutions/Choose/4.svg';

import Assessment from '../../images/CloudSolutions/Keys/1.svg';
import Architecture from '../../images/CloudSolutions/Keys/2.svg';
import Development from '../../images/CloudSolutions/Keys/3.svg';
import SecurityCompliance from '../../images/CloudSolutions/Keys/4.svg';
import Testing from '../../images/CloudSolutions/Keys/5.svg';
import DeploymentOngoing from '../../images/CloudSolutions/Keys/6.svg';

import AWS from '../../images/CloudSolutions/Tech/1.svg';
import Azure from '../../images/CloudSolutions/Tech/2.svg';
import MSSql from '../../images/CloudSolutions/Tech/3.svg';
import Sql from '../../images/CloudSolutions/Tech/4.svg';
import GoogleCloud from '../../images/CloudSolutions/Tech/5.svg';
import Firebase from '../../images/CloudSolutions/Tech/6.svg';

const dataForServices = {
  ogImage: OgImage,
  service: "cloud",
  banner: {
    title: "Cloud Software Development Services",
    subtitle: "Transform your business with high-performance cloud solutions.",
    description: "Let our experts guide you through seamless cloud adoption and optimization.",
  },
  explore: {
    title: "Explore Our Expertise With Cloud Service",
    description: "Unlock the unlimited potential of Cloud Technology with our end-to-end solutions. Scalable, secure, high-performance cloud services for your enterprise, tuned to your needs. Whether migration, optimization, or development, frictionless integration, and peak efficiency are assured.",
    images: {CloudMigration, Infrastructure, Native, Devops, CloudSecurity, MultiCloud},
  },
  services: {
    title: "Why Choose Our Cloud Services?",
    cases: [
      {
        title: "99,99% Uptime Guarantee",
        description: "Ensure high availability and uninterrupted operations with our reliable cloud solutions.",
        number: "01",
      },
      {
        title: "30% Cost Savings",
        description: "Optimize infrastructure costs with efficient resource allocation and pay-as-you-go models.",
        number: "02",
      },
      {
        title: "5X Faster Deployment",
        description: "Accelerate software delivery with automated CI/CD pipelines and cloud-native development.",
        number: "03",
      },
      {
        title: "24/7 Security Monitoring",
        description: "Protect your cloud environment with real-time threat detection and compliance management.",
        number: "04",
      },
      {
        title: "Multi-Cloud Expertise",
        description: "Seamlessly integrate AWS, Azure, and Google Cloud for improved flexibility and performance.",
        number: "05",
      },
    ]
  },
  computing: {
    title: "Why You Need Cloud Computing",
    description: "Cloud computing innovates the working style of companies through scalability, cost-effectiveness, and flexibility. Be it enhanced security, improved performance, or smoothing collaboration, cloud solutions are offering companies a competitive edge.",
    cases: [
      {
        image: Scalability,
        title: "Scalability and Flexibility",
        description: "Scale resources easily up or down depending on the demand. Therefore, cloud computing will enable businesses to manage periods of peak work without overinvestment in infrastructural facilities. This ensures performance and cost efficiency.",
      },
      {
        image: CostSavings,
        title: "Cost Savings",
        description: "Reduce IT costs with a pay-as-you-use model. Eliminate costly hardware and reduce overall maintenance as Cloud Computing frees resources for efficient deployment by a company to drive innovation.",
      },
      {
        image: Security,
        title: "Improved Security and Compliance",
        description: "Safeguard your data with enterprise-grade security measures. Cloud providers employ encryption, multi-factor authentication, and compliance certifications to keep sensitive information safe and compliant with regulations.",
      },
      {
        image: Collaboration,
        title: "Higher Collaboration and Productivity",
        description: "Enable teams to work anywhere with cloud-enabled tools. Cloud computing improves collaboration through real-time access to files, applications, and communication platforms to increase efficiency and teamwork.",
      },
      {
        image: Business,
        title: "Business Continuity and Disaster Recovery",
        description: "Minimize downtime by automating backups and setting up failover solutions. With cloud computing, data is securely stored and can be restored quickly to keep business operations running in case of disruptions.",
      },
      {
        image: Deployment,
        title: "Faster Deployment and Innovation",
        description: "Speed up software development cycles using cloud-based DevOps tools. Cloud computing enables fast testing, deployment, and scaling of applications. This way, businesses can innovate and adapt faster to changes in the market.",
      }
    ]
  },
  industries: {
    title: "Industries We Serve",
    description: "Our cloud infrastructure development services cater to a wide range of industries and have helped organizations move towards efficiency, security, and scalability. Be it finance, healthcare, or e-commerce, rest assured that our experience in the cloud assures seamless digital transformation.",
    cases: [
      {
        image: Finance,
        title: "Finance & Banking",
        description: "Secure and scalable cloud for Digital Banking, Fraud Detection & Data Management.",
      },
      {
        image: Healthcare,
        title: "Healthcare",
        description: "HIPAA Cloud for Telemedicine, Storage, and Analytics in real-time.",
      },
      {
        image: Ecommerce,
        title: "E-commerce & Retail",
        description: "Scalable platforms for online stores, personalized customer experiences, and inventory management.",
      },
      {
        image: Manufacturing,
        title: "Manufacturing",
        description: "IoT-powered cloud solutions for smart factories, optimizing the supply chain, and predictive maintenance.",
      },
      {
        image: TechSaaS,
        title: "Tech & SaaS",
        description: "High-performance cloud environments for software development, AI, and machine learning applications.",
      },
      {
        image: Education,
        title: "Education",
        description: "Cloud-based e-learning platforms, remote collaboration tools, and secure data storage for institutions.",
      }
    ]
  },
  choose: {
    title: "Why you should choose us",
    description: "With OS System, you will be working with professionals who focus on secure, scalable, and high-performance solutions. Our cloud development company ensures seamless cloud software development services, optimized cost efficiency, and ongoing support.",
    cases: [
      {
        image: ProvenCloud,
        title: "Proven Cloud Expertise",
        description: "With extensive experience in cloud computing, we design and implement tailored solutions for businesses of all sizes. Our profound knowledge of AWS, Azure, and Google Cloud guarantees seamless deployments and long-term scalability."
      },
      {
        image: CostOptimization,
        title: "Cost-Operational Optimization",
        description: "We enable enterprises to lower cloud costs by optimizing infrastructure, automating processes, and putting in place effective resource management. Our aim is high performance at lower unnecessary costs."
      },
      {
        image: SecurityFirst,
        title: "Security-First Approach",
        description: "Strong security with advanced encryption, multi-factor authentication, and compliance-based frameworks are our major concerns. Our cloud solutions meet industry regulations, thereby keeping your sensitive data very safe from cyber threats and breaches."
      },
      {
        image: FullCycle,
        title: "Full-Cycle Cloud Development Services",
        description: "From strategy and migration to security and optimization, we provide full-cycle cloud solutions development services. Our approach to cloud development services ensures that your cloud infrastructure is efficient, secure, and aligned with your business goals."
      },
    ]
  },
  keys: {
    title: "Steps of Cloud Development Services",
    description: "The cloud development service at our company is built on a properly detailed process. Herein lies how we deliver exceptional solutions with respect to your business.",
    cases: [
      {
        image: Assessment,
        number: "01",
        title: "Assessment & Strategy",
        description: "We start by understanding your business objectives, current infrastructure, and detailed requirements. We conduct thorough assessments and suggest the right cloud platform for a customer (AWS, Azure, Google Cloud). Then, we design a custom cloud strategy.",
      },
      {
        image: Architecture,
        number: "02",
        title: "Cloud Architecture Design",
        description: "We're here to develop a robust cloud architecture for your business: highly scalable, totally secure, and capable of managing future growth. Much attention is paid to the right choice of the cloud model and the infusion of best practices like microservices, containerization, Docker, Kubernetes, and automation for efficient workflows.",
      },
      {
        image: Development,
        number: "03",
        title: "Development & Integration",
        description: "At this stage, we design cloud-native applications or migrate your workloads to the cloud. We use the latest tools, such as APIs, serverless computing, and container orchestration, to ensure that these integrate properly with your current systems.",
      },
      {
        image: SecurityCompliance,
        number: "04",
        title: "Security & Compliance Implementation",
        description: "Security is a top priority in our cloud solutions. We implement enterprise-grade security measures, including end-to-end encryption, multi-factor authentication, and role-based access control. What is more, we will make sure your solution will comply with industry-specific standards of compliance: HIPAA, GDPR, and PCI DSS.",
      },
      {
        image: Testing,
        number: "05",
        title: "Testing & Optimization",
        description: "Performance, security, and scalability tests are all included in our testing process. We reproduce actual workloads to spot potential bottlenecks and act to resolve them. Based on the outcome of such tests, we optimize resources, fine-tune configurations, and make sure the system is at its best.",
      },
      {
        image: DeploymentOngoing,
        number: "06",
        title: "Deployment & Ongoing Support",
        description: "After thorough testing, we go live with your cloud solution. Our deployment strategy minimizes downtime, ensuring a seamless transition. We will also provide post-launch monitoring, 24/7 support, and regular updates.",
      }
    ]
  },
  tech: {
    title: "Our Team's Expertise",
    cases: [AWS, Azure, MSSql, Sql, GoogleCloud, Firebase],
  }
}

export default dataForServices
