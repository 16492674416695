import React from "react";
import './Necessary.scss';

import dataForCloud from "../../../information/CloudSolutions/dataForServices"

export default function Necessary() {
  const { title, description, images } = dataForCloud.explore;
  return (
    <section className="necessary-cloud-solutions-wrapper">
      <div className="necessary-text">
        <h2 className="necessary-title">{title}</h2>
        <div className="necessary-description">{description}</div>
      </div>
      <div className="necessary-cases">
        <div className="necessary-case">
          <div className="case-img-container">
            <img src={images.CloudMigration} alt="web development icon" />
          </div>
          <div className="case-text">
            <h3 className="case-title">Cloud Migration</h3>
            <div className="case-description">Seamlessly transition your workloads to the cloud with minimal disruption. Our experts handle end-to-end migration, ensuring data integrity, optimized performance, and cost efficiency. Downtime will be reduced. We use the latest practices to minimize the impact on the system while transferring it to the cloud.</div>
          </div>
        </div>

        <div className="necessary-case">
          <div className="case-img-container">
            <img src={images.Infrastructure} alt="web development icon" />
          </div>
          <div className="case-text">
            <h3 className="case-title">Cloud Infrastructure Management</h3>
            <div className="case-description">Attain maximum cloud efficiency by proactive monitoring, automation, and optimization. We manage your cloud infrastructure for scalability, security, and cost control, with peak performance at all times.</div>
          </div>
        </div>

        <div className="necessary-case">
          <div className="case-img-container">
            <img src={images.Native} alt="web development icon" />
          </div>
          <div className="case-text">
            <h3 className="case-title">Cloud-Native Development</h3>
            <div className="case-description">We build cloud solutions for our clients based on the latest technologies and methodologies. Our team can help you develop new solutions, establish modern practices for a successful continuous integration setup, and establish the full continuous deployment pipeline for your project. We will help you by adopting containerization, microservices, and serverless architecture.</div>
          </div>
        </div>

        <div className="necessary-case">
          <div className="case-img-container">
            <img src={images.Devops} alt="web development icon" />
          </div>
          <div className="case-text">
            <h3 className="case-title">DevOps and CI/<br className="case-title-break"/>CD Integration</h3>
            <div className="case-description">Accelerate your development cycles with our DevOps and CI/CD solutions. We implement automated workflows, continuous integration, and seamless deployment strategies that make for more agile and reliable services.</div>
          </div>
        </div>

        <div className="necessary-case">
          <div className="case-img-container">
            <img src={images.CloudSecurity} alt="web development icon" />
          </div>
          <div className="case-text">
            <h3 className="case-title">Cloud Security and Compliance</h3>
            <div className="case-description">Advanced security for your cloud environment. We ensure compliance with industry regulations, strong access controls, and threat monitoring in place to protect your data. Get consulted by a team of cloud development professionals to transform your business. We provide our customers with strategic consulting, planning, delivery, and support in the field of cloud solutions.</div>
          </div>
        </div>

        <div className="necessary-case">
          <div className="case-img-container">
            <img src={images.MultiCloud} alt="web development icon" />
          </div>
          <div className="case-text">
            <h3 className="case-title">Multi-Cloud and Hybrid Cloud Solutions</h3>
            <div className="case-description">Get the best out of a multi-cloud environment with us. We design and implement multi-cloud and hybrid strategies to optimize performance, cost, and redundancy. This we guarantee through seamless connectivity.</div>
          </div>
        </div>
      </div>
    </section>
  );
};
