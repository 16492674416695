import React from "react"
import "./Services.scss"

import dataForCloud from "../../../information/CloudSolutions/dataForServices"

const CaseComponent = ({ data }) => {
  const { title, description, number } = data;
  return (
    <div className="service-case">
      <div className="service-title-container">
        <div className="service-case-number">{number}</div>
        <div className="service-case-title">{title}</div>
      </div>
      <div className="service-case-description">{description}</div>
    </div>
  )
}


export default function Services() {
  const { title, cases } = dataForCloud.services;
  return (
    <section className="services-wrapper">
      <h2 className="services-title">{title}</h2>
      <div className="services-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </section>
  )
}